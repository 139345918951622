import React, {useState, useEffect} from "react"
import Form from 'react-bootstrap/Form';
import shortid from 'shortid';
import callApi from "../../../util/apiCaller";
import { errorMsg, successMsg } from "../../../util/toast";
import callApiV2 from "../../../util/apiV2Caller";

const AddCollection = (props) => {

    const [collection, setCollection] = useState({
        restaurant_id : props.restaurantId,
        name : "",
        tile_position : "1",
        screen_position : "1",
        code : shortid.generate(),
        item_num : "1",
        is_active : false
    })

    const handleChange = (key, val) => {
        let update = {...collection}
        update[key] = val
        setCollection(update)
    }

    const handleSave = () => {
        callApiV2("menu_collection/new", "post", {
            menu_collection : collection
        })
        .then(res => {
            if(res.status === "Success"){
                successMsg("Menu Collection added Successfully!")
                props.fetchMenuCollection()
                props.closeModal()
            }
            if(res.status === "Error"){
                errorMsg(res?.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    console.log("new collection", collection)
    return <div>
    <div className="d-flex justify-content-around" style={{width : "100%"}}>
        <div className="col-md-5">
        <Form className="menu-information">
            <Form.Label className="events-mt">Name </Form.Label>
            <Form.Control
                value={collection?.name}
                type="text"
                placeholder="Enter Collection Name"
                onChange={(e) => handleChange("name", e.target.value)}
            />
            <Form.Label className="events-mt">Tile Position </Form.Label>
            <Form.Select className="select-event menu-select" width="100%"
                        value={collection?.tile_position}
                        onChange={(e) => handleChange("tile_position", e.target.value)}
            >
                <option value="">Not Selected</option>
                {props.screenPosition?.map(item => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                ))}
            </Form.Select>
            <Form.Label className="events-mt">Code </Form.Label>
            <Form.Control
                value={collection?.code}
                type="text"
                placeholder="Enter Collection Name"
                disabled
            />
            
            </Form>

            <br></br>
            <br></br>
            <div style={{display:"flex", justifyContent :"space-between", backgroundColor : "white", borderRadius : "8px", border:"1px solid #0001", padding : "10px"}}>
                <Form.Label style={{margin : 0}}>Price Shown</Form.Label>
                <label class="switch">
                    <input type="checkbox" checked={collection?.is_price_shown}
                            onClick={() => handleChange("is_price_shown",!collection?.is_price_shown)}/>
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div className="col-md-5">
        <Form>
        <Form.Label className="events-mt">Screen Position </Form.Label>
        <Form.Select
            className="select-event menu-select" width="100%"
            value={collection?.screen_position}
            onChange={(e) => handleChange("screen_position", e.target.value)}
            placeholder="Please Enter Screen Position"
        >
            <option value="">Not Selected</option>
            {props.screenPosition?.map(item => (
                <option key={item.value} value={item.value}>{item.label}</option>
            ))}
        </Form.Select>
        <Form.Label className="events-mt">Items </Form.Label>
        <Form.Control
                value={collection?.item_num}
                type="number"
                placeholder="Enter Item Number"
                min={0}
                onChange={(e) => handleChange("item_num", e.target.value)}
            />
        <br></br>
        <br></br>
        <div style={{display:"flex", justifyContent :"space-between", backgroundColor : "white", borderRadius : "8px", border:"1px solid #0001", padding : "10px"}}>
            <Form.Label style={{margin : 0}}>Active</Form.Label>
            <label class="switch">
                <input type="checkbox" checked={collection?.is_active}
                        onClick={() => handleChange("is_active",!collection.is_active)}/>
                <span class="slider round"></span>
            </label>
        </div>
        
    </Form>

    </div>
    
    </div>
    <div className="event-buttons" style={{padding : "4%"}}>
        <div className="cancel-btn" style={{flex : "0 0 35%"}} onClick={props.closeModal}>
            <button style={{fontSize : "12px"}}>Cancel</button>
        </div>
        <div className="save-btn" style={{flex : "0 0 35%"}} onClick={handleSave}>
            <button style={{ fontSize : "12px"}}>Save</button>
        </div>
    </div>
    </div>
}

export default AddCollection