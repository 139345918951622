import React, {useEffect, useRef, useState} from "react";

import Form from "react-bootstrap/Form";
//import callApi, {callUploadApi} from "../../../util/apiCaller";
import {convertImageBaseUrl} from '../../../util/imageUpload';
import styled from "styled-components";
import FormData from 'form-data';
import callApiV2, {callUploadApi} from "../../../util/apiV2Caller";

const ImageUpload = styled.div`
  border: 1px solid #dbd9d9;
  width: auto;
  height: 168px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  display: block;

  h6 {
    padding: 60px;
  }

  h5 {
    margin-top: 50px;
  }

  span {
    color: #606734;
  }

  img {
    border: 1px solid #dbd9d9;
    width: auto;
    height: 168px;
    border-radius: 8px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const DishScreenDetails = (props) => {


    const [dishInfoNew, setdishInfoNew] = useState({});
    const [errors, setError] = useState({});
    const [dishRowSpan, setDishRowSpan] = useState([])
    const [dishColumnSpan, setDishColumnSpan] = useState([])

    // const [imageId, setImageId] = useState(0);
    var imageId = useRef(0)

    useEffect(() => {
        setdishInfoNew(props.dishInfo);
    }, [props.dishInfo]);

    const fetchContentByCode = (code, next) => {
        callApiV2(`content/code/${code}`, 'get').then(res => {
            if (res.status === "Success") {
                return next(null, res.data.content);
            }
        }).catch(err => {
            console.log(err)
            return next(err);
        })
    }


    useEffect(() => {
        fetchContentByCode('dish-column-span', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setDishColumnSpan(content?.params)
            }
        });

        fetchContentByCode('dish-row-span', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setDishRowSpan(content?.params)
            }
        });
    }, [])

    const deleteFileUploadFileInput = () => {
        // native JS
        let fileInput = document.getElementById("PageUpload");
        document.getElementById("root").removeChild(fileInput);
    };
    const onFileUpload = (file) => {
      
        const formdata = new FormData();
        formdata.append("file", file);
        callUploadApi(`media-upload`, "post", formdata)
            .then(res => {
               
                let updateEvent = {};
                let keyName = document.getElementById("PageUpload").dataset.keyName;
                updateEvent[keyName] = res.data.link;
                props.handleChangedish(keyName, res.data.link);
                //   console.log(updateEvent, "updateKitchen");
                //updateEvent(eventId, updateEvent);
                deleteFileUploadFileInput();
            });
    };
    const onFileChange = (event) => {

        onFileUpload(event.target.files[0]);
    };


    const handleFormUpload = (val, id) => {
        imageId.current = id
        let input = document.createElement("input");
        input.type = "file";
        input.id = "PageUpload";
        input.style.display = "none";
        input.dataset.keyName = val;
        input.onchange = onFileChange;
        document.getElementById("root").appendChild(input);
        setTimeout(function () {
            document.getElementById("PageUpload").click();
        }, 200);
    };

    const deleteImage = (imageKey) => {
        props.handleChangedish(imageKey, "")
    }

    const handleImageSave = () => {
        props.handleSaveMenu(props.dishId, dishInfoNew)
    }

    return (
        <>
            <div className="col-md-6">
                <Form>
                    <Form.Label className="mt-4 menu-information-image-head">Column span of tile</Form.Label>
                    <Form.Select
                        aria-label="Super Admin"
                        className="select-event menu-select input_bottom"
                        width="100%"
                        value={props.dishInfo?.column_span_tile}
                        onChange={(e) => props.handleChangedish("column_span_tile", e.target.value)}
                    >
                        {
                            dishColumnSpan?.map(item => {
                                return (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                )
                            })
                        }
                    </Form.Select>
                    <br/>
                    <Form.Label className="mt-4 menu-information-image-head">Row span of tile</Form.Label>
                    <Form.Select
                        aria-label="Super Admin"
                        className="select-event menu-select input_bottom"
                        width="100%"
                        value={props.dishInfo?.row_span_tile}
                        onChange={(e) => props.handleChangedish("row_span_tile", e.target.value)}
                    >
                        {
                            dishRowSpan?.map(item => {
                                return (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                )
                            })
                        }
                    </Form.Select>
                    <h5 className="menu-information-image-head mt-4">
                        {props.dishId ? "Thumbnail Image" : "Tile Image Upload"}
                    </h5>
                    <div className="image_upload_box">
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{padding: "20px"}}
                        >
                            {props.dishInfo?.thumbnail_url ? (
                                <div className="up_box">
                                    <img src={convertImageBaseUrl(props.dishInfo?.thumbnail_url)} id="output"/>
                                    <div onClick={() => deleteImage("thumbnail_url")}>
                                        <img
                                            src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                                            className="img_position"
                                        />
                                    </div>
                                </div>
                            ) : null}

                            <div
                                style={{
                                    margin: "0 auto",
                                    border: "1px solid rgba(50, 50, 50, 0.2)",
                                    height: "116px",
                                    borderRadius: "15px",
                                    padding: "40px 20px",
                                }}
                            >

                                {props.dishInfo?.thumbnail_url ? (
                                    <h6 onClick={() => handleFormUpload("thumbnail_url", 1)}
                                        style={{cursor: "pointer"}}>
                                        change image
                                    </h6>
                                ) : (
                                    <h6 style={{color: "#d7d7d7"}}>
                                        Upload Group Menu Image{" "}
                                        <span
                                            onClick={() => handleFormUpload("thumbnail_url", 1)}
                                            style={{color: "#202738", cursor: "pointer"}}
                                        >
                        Browse
                      </span>
                                    </h6>
                                )}
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="col-md-6">
                <Form>
                    {/* <Form.Label className="">Image</Form.Label> */}
                    <div className="information-input-box events-mt">
                        <h5 className="menu-information-image-head">
                            {props.dishId ? "Update Image" : "Image"}
                        </h5>
                        <div className="image_upload_box">
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{padding: "20px"}}
                            >
                                {props.dishInfo?.image_url ? (
                                    <div className="up_box">
                                        <img src={convertImageBaseUrl(props.dishInfo?.image_url)} id="output"/>
                                        <div onClick={() => deleteImage("image_url")}>
                                            <img
                                                src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                                                className="img_position"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div
                                    style={{
                                        margin: "0 auto",
                                        border: "1px solid rgba(50, 50, 50, 0.2)",
                                        height: "116px",
                                        borderRadius: "15px",
                                        padding: "40px 20px",
                                    }}
                                >
                                    {props.dishInfo?.image_url ? (
                                        <h6 onClick={() => handleFormUpload("image_url", 2)}
                                            style={{cursor: "pointer"}}>
                                            change image
                                        </h6>
                                    ) : (
                                        <h6 style={{color: "#d7d7d7"}}>
                                            Upload Group Menu Image{" "}
                                            <span
                                                onClick={() => handleFormUpload("image_url", 2)}
                                                style={{color: "#202738", cursor: "pointer"}}
                                            >
                        Browse
                      </span>
                                        </h6>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <Form.Label className="">Side Image Upload</Form.Label> */}
                        <div className="information-input-box events-mt">
                            <h5 className="menu-information-image-head">
                                {props.dishId ? "Side Image" : "Side Image Upload"}
                            </h5>
                            <div className="image_upload_box">
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{padding: "20px"}}
                                >
                                    {props.dishInfo?.side_image_url ? (
                                        <div className="up_box">
                                            <img src={convertImageBaseUrl(props.dishInfo?.side_image_url)} id="output"/>
                                            <div onClick={() => deleteImage("side_image_url")}>
                                                <img
                                                    src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                                                    className="img_position"
                                                />
                                            </div>
                                        </div>
                                    ) : null}

                                    <div
                                        style={{
                                            margin: "0 auto",
                                            border: "1px solid rgba(50, 50, 50, 0.2)",
                                            height: "116px",
                                            borderRadius: "15px",
                                            padding: "40px 20px",
                                        }}
                                    >
                                        {props.dishInfo?.side_image_url ? (
                                            <h6 onClick={() => handleFormUpload("side_image_url", 3)}
                                                style={{cursor: "pointer"}}>
                                                change image
                                            </h6>
                                        ) : (
                                            <h6 style={{color: "#d7d7d7"}}>
                                                Upload Group Menu Image{" "}
                                                <span
                                                    onClick={() => handleFormUpload("side_image_url", 3)}
                                                    style={{color: "#202738", cursor: "pointer"}}
                                                >
                        Browse
                      </span>
                                            </h6>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="d-flex justify-content-between">
                <button
                    className="cancel_but"
                    onClick={() => props.setSegment("Information")}
                >
                    Back
                </button>
                <button className="save_but" onClick={() => handleImageSave()}>
                    Save
                </button>
            </div>
        </>
    );
};

export default DishScreenDetails;
