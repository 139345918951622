import "./events.css"

import React, {useEffect, useState} from "react";
import {errorMsg, successMsg} from "../../util/toast";

import Banner from "./Components/Banner";
import EventTable from "./Components/EventTable";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import MenuLogo from "../../assets/menu-logo.png";
import callApi from "../../util/apiCaller";
import callApiV2 from "../../util/apiV2Caller";
import {getSelectedRestaurant} from "../../redux/reducers/AppReducer";
import {useSelector} from 'react-redux';

const Events = () => {

    const [event_count, setEventCount] = useState(0);
    const [eventList, seteventList] = useState([]);
    const [eventInfo, setEventInfo] = useState({});
    const restaurant = useSelector(state => getSelectedRestaurant(state));

    const fetchEventList = (restaurant_id) => {
        callApiV2(`event/list`, 'post', {
            filters: {
                restaurant_id: restaurant_id
            }
        }).then(res => {
            console.log("response", res);
            if (res.status === "Success") {
                seteventList(res?.data?.list)
                setEventCount(res?.data?.count)
            }
        })
    }

    // const handleSaveBanner = (bannerId, _eventInfo) => {
    //     console.log("bannerId", bannerId)
    //     if (bannerId) {
    //         callApi(`banner/${bannerId}/update`, 'post', {
    //             "banner": {
    //                 name: "alka",
    //                 image_url: "https://windmills-india.com/images/Windmills-blr-v1_Menu-WIndmills.png",
    //                 is_active: true
    //             }
    //         }).then(res => {
    //             if (res.status === "Success") {
    //                 //setMenuList(res.data.detail);
    //                 successMsg("banner updated Successfully...!");
    //             } else {
    //                 errorMsg("something went wrong");
    //             }
    //         });
    //     } else {
    //         callApi(`banner/new`, 'post', {
    //             "banner": {
    //                 name: "alka",
    //                 image_url: "https://windmills-india.com/images/Windmills-blr-v1_Menu-WIndmills.png",
    //                 is_active: true
    //             }
    //         }).then(res => {
    //             if (res.status === "Success") {
    //                 //setMenuList(res.data.detail);
    //                 successMsg("banner updated Successfully...!");
    //             } else {
    //                 errorMsg("something went wrong");
    //             }
    //         });
    //     }
    // }

    const handlePageSize = (pageSize) => {
        let searchFilters = {...filterData};
        searchFilters.pageSize = parseInt(pageSize);
        searchFilters.pageNum = 1;
        setFilterData(searchFilters);
        fetchEventList(restaurant._id, searchFilters);
    };

    const handlePageNum = (pageNum) => {
        let searchFilters = {...filterData};
        searchFilters.pageNum = parseInt(pageNum);
        setFilterData(searchFilters);
        fetchEventList(restaurant._id, searchFilters);
    };

    const [filterData, setFilterData] = useState({});

    useEffect(() => {
        setFilterData({
            qtext: '',
            pageNum: 1,
            pageSize: 50,
        })
        fetchEventList(restaurant?._id);
    }, [restaurant]);

    const handleChangeGroupEvent = (keyName, keyValue) => {
        console.log("keyyyyy", keyName, keyValue);
        let update = {...eventInfo}
        update[keyName] = keyValue;
        console.log("update", update);
        setEventInfo(update)
    }
    console.log("concertAlka", eventList);
    return (
        <>
            <div className="employee-header">
                <Helmet>
                    <title>Windmills | Events</title>
                </Helmet>
                <div><img src={MenuLogo}/></div>
            </div>
            <div className="employee-part">
                <div className="employee-section">
                    <p>Events</p>
                    <div className="d-flex">
                        <Link to="/admin/events/add-events">
                            <button className="add-employee-btn">Add Events</button>
                        </Link>
                        <Banner
                            eventInfo={eventInfo}
                        />
                    </div>
                </div>
                <div className="employee-list-bg">
                    <EventTable
                        eventList={eventList}
                        event_count={event_count}
                        handlePageSizeChange={handlePageSize}
                        handlePageNumChange={handlePageNum}
                        pageSize={filterData.pageSize}
                        pageNum={filterData.pageNum}
                        pages={[10, 20, 50, 100]}

                    />

                </div>
            </div>
        </>
    )

}

export default Events;