import React, {useEffect, useState} from 'react'
import Form from "react-bootstrap/Form";
import {getRestaurantList} from '../../../redux/reducers/AppReducer';
import {useSelector} from "react-redux"

function EnumContainer({record, getRecordValue, removeParam, handleAddOrUpdate, groupNames}) {
    const [data, setData] = useState({
        restaurant_id: "",
        table_name: "",
        table_num: "",
        group_name: "",
        table_rank: 1,
        table_seating_count: "",
        is_active: false
    })

    const restuarant_list = useSelector(state => getRestaurantList(state));

    useEffect(() => {

        setData(record)

    }, [record])

    const getRestaurantName = (id) => {
        let res = restuarant_list.filter(res => res._id === id)

        return res[0]?.name
    }

    const handleChange = (keyName, value) => {
        let newData = {...data}

        if (keyName == 'group_name') {
            console.log(keyName, value);
            newData['group_rank'] = 1;
            newData['group_name'] = groupNames.filter(item => item.value == value)[0]?.label
            console.log(newData);
        } else {
            newData[keyName] = value
        }

        getRecordValue(newData)
        setData(newData)
    }

    // console.log("group_name", groupNames);

    return (
        <tr className="table-border-bottom table-body" style={{}}>

            {/*<td>*/}
            {/*    <Form.Group className="table-record-td" >*/}
            {/*        <Form.Control*/}
            {/*            value = {data?.restaurant_id ? getRestaurantName(data?.restaurant_id) : null}*/}
            {/*            type="text"*/}
            {/*            placeholder="Restaurant Name"*/}
            {/*            disabled={true}*/}
            {/*        />*/}
            {/*    </Form.Group>*/}
            {/*</td>*/}
            <td><Form.Group className="table-record-td">
                <Form.Control
                    value={data?.table_name}
                    onChange={(e) => handleChange("table_name", e.target.value)}
                    type="text"
                    placeholder="Enter Table Name"
                />
            </Form.Group>
            </td>
            <td><Form.Group className="table-record-td">
                <Form.Control
                    value={data?.table_num}
                    onChange={(e) => handleChange("table_num", e.target.value)}
                    type="text"
                    placeholder="Enter Table Number"
                />
            </Form.Group>
            </td>
            <td><Form.Group className="table-record-td">
                <Form.Control
                    value={data?.digitory_table_id}
                    onChange={(e) => handleChange("digitory_table_id", e.target.value)}
                    type="text"
                    placeholder="Enter Digitory Table Id"
                />
            </Form.Group>
            </td>
            <td><Form.Group  className="table-record-td"  >
                    <Form.Control
                        value = {data?.table_rank}
                        onChange = {(e) => handleChange("table_rank", e.target.value)}
                        type="number"
                        min={1}
                        placeholder="Enter Table Rank"
                    />
                </Form.Group>
            </td>
            <td>
                <Form.Group className="table-record-td" style={{minWidth : '150px'}}>
                    <Form.Select
                        aria-label="Group Name"
                        value={data?.group_name}
                        width="100%"
                        onChange={(e) => handleChange("group_name", e.target.value)}
                    >
                        <option value="">{""}</option>
                        {groupNames?.map(item => <option key={item.value.toString()} value={item.value.toString()}>{item.label}</option>)}
                    </Form.Select>
                </Form.Group>
            </td>
            <td><Form.Group  className="table-record-td"  >
                    <Form.Control
                        value = {data?.table_seating_count}
                        onChange = {(e) => handleChange("table_seating_count", e.target.value)}
                        type="text"
                        placeholder="Table Seating Count"
                    />
                </Form.Group>
            </td>
            <td data-tip="Inactive" data-class="upload-data-tip">
                <div className="table-record-td" style={{height: '57px'}}>
                    <label className="switch">
                        <input type="checkbox"
                               checked={data?.is_active}
                               onChange={() => handleChange("is_active", !data.is_active)}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            </td>
            <td><Form.Group className="table-record-td">
                <Form.Control
                    value={data?.is_table_help_requested ? "YES" : "NO"}
                    type="text"
                    disabled
                />
            </Form.Group>
            </td>
            <td>
                <Form.Group className="table-record-td">
                    <button className="common_button" onClick={() => removeParam()}>Delete</button>
                </Form.Group>
            </td>
            <td onClick={() => handleAddOrUpdate(record._id, record?.new)}>
                <Form.Group className="table-record-td">
                    <div style={{textAlign: "center"}}>
                        <button
                            className="submit_but common_button">{!record?.new ? "Update table" : "Add table"}</button>
                    </div>
                </Form.Group>
            </td>

        </tr>

    )
}

export default EnumContainer