import callApiV2 from "./apiV2Caller";

export function createOTP(num) {
    let password = Math.floor(1000 + Math.random() * 9000);
    return password;
}


export const fetchContentByCode = (code, next) => {
    callApiV2(`content/code/${code}`, 'get').then(res => {
        if (res.status === "Success") {
           return next(null, res.data.content);
        }
    }).catch(err => {
        console.log(err)
        return next(err);
    })
}
export const handlePageSize = (filterData, setFilterData, pageSize) => {
    let searchFilters = {...filterData};
    searchFilters.pageNum = 1;
    searchFilters.pageSize = parseInt(pageSize);
    setFilterData(searchFilters);
};

export const handlePageNum = (filterData, setFilterData, pageNum) => {
    let searchFilters = {...filterData};
    searchFilters.pageNum = parseInt(pageNum);
    setFilterData(searchFilters);
};
