import React, {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
//import callApi, {callUploadApi} from "../../util/apiCaller";
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import TopLeftIcon from "../../assets/top-left.svg";
import TopRightIcon from "../../assets/top-right.svg";
import TopMiddleIcon from "../../assets/top-middle.svg";
import CenterLeftIcon from "../../assets/center-left.svg";
import {toast} from 'react-toastify';
import _ from "lodash";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {createOTP} from "../../util/Utility";
import FormData from 'form-data';
import callApiV2, {callUploadApi} from "../../util/apiV2Caller";
import { useSelector } from "react-redux";
import { getSelectedRestaurant } from "../../redux/reducers/AppReducer";
import { DatePicker } from "@mui/x-date-pickers";

const yes = "success";
const no = "error";
const update = "update";
const noUpdate = "noUpdate";

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ImageUpload = styled.div`
  border: 1px solid #dbd9d9;
  width: auto;
  height: 168px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  display: block;

  h6 {
    padding: 60px;

  }

  h5 {
    margin-top: 50px;

  }

  span {
    color: #606734;
  }

  img {
    border: 1px solid #dbd9d9;
    width: auto;
    height: 168px;
    border-radius: 8px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

`

const AddEvent = (props) => {
    const restaurant = useSelector(state => getSelectedRestaurant(state))
    const [event, setEvent] = useState({
        name: "",
        image_url: "",
        country_code: "",
        phone_number: 0,
        artist: "",
        cheaper_seating_price: "",
        pos_id: "",
        description: "",
        start_date: "",
        time: "",
        price: "",
        start_date_time: "",
        end_date_time: "",
        start_date_time_display: "",
        end_date_time_display: "",
        event_position: "",
        column_span_of_tile: 1,
        row_span_of_tile: 0,
    })
    const [errors, setErrors] = useState({});
    const query = useQuery();
    const eventId = query.get('id');
    console.log(eventId);
    const notify = (data) => {
        if (data === "success") {
            toast("Event added successfully..!")
        } else if (data === "error") {
            toast("Something went wrong..!")
        } else if (data === "update") {
            toast("Event Updated")
        } else if (data === "noUpdate") {
            toast("Something went wrong..!")
        }

    }
    const deleteFileUploadFileInput = () => {
        // native JS
        let fileInput = document.getElementById("fssaiPageUpload");
        document.getElementById('root').removeChild(fileInput)
    };
    const onFileUpload = (file) => {
       
        const formdata = new FormData();
        formdata.append("file", file);
        callUploadApi(`media-upload`, "post", formdata)
            .then(res => {
               
                let updateEvent = {};
                let keyName = document.getElementById("fssaiPageUpload").dataset.keyName;
                updateEvent[keyName] = res.data.link;
                
                //updateEvent(eventId, updateEvent);
                deleteFileUploadFileInput();
            });
    };
    const onFileChange = event => {
        // Update the state
        
        onFileUpload(event.target.files[0]);
    };
    const handleFormUpload = (val) => {
        let input = document.createElement('input');
        input.type = "file";
        input.id = "fssaiPageUpload";
        input.style.display = 'none';
        input.dataset.keyName = val;
        input.onchange = onFileChange;
        document.getElementById('root').appendChild(input);
        setTimeout(function () {
            document.getElementById("fssaiPageUpload").click();
        }, 200);
    };

    const handleAddEvent = (Newevent, next) => {
        
        callApiV2(`event/new`, 'post',
            {event: Newevent}
        ).then(res => {
            return next(res);
        })
    }

    const handleUpdateEvent = (newEvent, next) => {
        
        callApiV2(`event/${eventId}/update`, 'post',
            newEvent
        ).then(res => {
            return next(res);
        })
    };

    useEffect(() => {
        if(eventId){
        callApiV2(`event/${eventId}`, 'get').then(res => {
            if (res.status === "Success") {
               
                setEvent(res.data.event);
            } else {
                notify(no);
            }
        })
    }
    }, [eventId]);
    const handleValidation = (event) => {
        let isValid = true;
        let newErrors = {};
        if (!event.name) {
            isValid = false;
            newErrors['name'] = 'Please enter name';
        }
        if (!event.artist) {
            isValid = false;
            newErrors['artist'] = 'Please enter artist name';
        }
        if (!event.price) {
            isValid = false;
            newErrors['price'] = 'Please enter price';
        }
        if (!event.cheaper_seating_price) {
            isValid = false;
            newErrors['cheaper_seating_price'] = 'Please enter cheaper price';
        }
        if (!event.pos_id) {
            isValid = false;
            newErrors['pos_id'] = 'Please enter pos ID';
        }
        if (!event.start_date_time) {
            isValid = false;
            newErrors['start_date_time'] = 'Please enter start date and time of event';
        }
        // if (!event.description) {
        //     isValid = false;
        //     newErrors['description'] = 'Please enter description of event.';
        // }
        if (!event.end_date_time) {
            isValid = false;
            newErrors['end_date_time'] = 'Please enter end date and time of event.';
        }
        if (!event.start_date_time_display) {
            isValid = false;
            newErrors['start_date_time_display'] = 'Please enter display start date and time of event.';
        }
        if (!event.end_date_time_display) {
            isValid = false;
            newErrors['end_date_time_display'] = 'Please enter display end date and time of event.';
        }
        // if (typeof event.phone["phone"] !== "undefined") {

        //     var pattern = new RegExp(/^[0-9\b]+$/);
        //     if (!pattern.test(event.phone["phone"])) {
        //         isValid = false;
        //         newErrors["phone"] = "Please enter only number.";
        //     } else if (event.phone["phone"].length < 10) {
        //         isValid = false;
        //         newErrors["phone"] = "Please enter valid phone number.";
        //     }
        // }
        // if (!event.assign_role) {
        //     isValid = false;
        //     newErrors['assign_role'] = 'Please select your role';
        // }
        return {
            isValid,
            errors: newErrors
        }
    };

    const handleChangeUser = (keyName, keyValue) => {
      // debugger;
        let update = {...event}
        update[keyName] = keyValue;
        
        setEvent(update);

    }
    let restaurantId = {
        restaurant_id : restaurant._id
    }
    let newEvents = {...event, ...restaurantId}
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        let newEvent = {...event}
        const check = handleValidation(newEvent);
        if (check.isValid) {
            let UpdateEvent = {
                event: newEvent
            }
            if (eventId) {
              
                handleUpdateEvent(UpdateEvent, (result) => {
                    if (result.status === "Success") {
                       
                        notify(update);
                    } else {
                        notify(noUpdate);
                    }
                });
            } else {
                
                handleAddEvent(newEvents, (result) => {
                    if (result.status === "Success") {
                      
                        notify(yes);
                    } else {
                        notify(no)
                    }
                });
            }
        } else {
            setErrors(check.errors)
        }
    }

    return (
        <>

            <div className="employee-part add-events-section">
                <p className="events-breadcrum">Events <span>/ Add Events</span></p>
                <div className="add-event-form">
                    <p>Events</p>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" className=""
                                      value={event.name}
                                      onChange={(e) => handleChangeUser("name", e.target.value)}

                        />

                        {errors.name ? <p className="add-employee-validation">{errors.name}</p> : null}
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Artist</Form.Label>
                        <Form.Control type="text" placeholder="Artist"
                                      value={event.artist}
                                      onChange={(e) => handleChangeUser("artist", e.target.value)}/>
                        {errors.artist ? <p className="add-employee-validation">{errors.artist}</p> : null}
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Price*</Form.Label>
                        <Form.Control type="number" placeholder="1000"
                                      value={event.price}
                                      onChange={(e) => handleChangeUser("price", e.target.value)}/>
                        {errors.price ? <p className="add-employee-validation">{errors.price}</p> : null}
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Cheaper Seating Price</Form.Label>
                        <Form.Control type="number" placeholder="Enter Cheaper Seating Price"
                                      value={event.cheaper_seating_price}
                                      onChange={(e) => handleChangeUser("cheaper_seating_price", e.target.value)}/>
                        {errors.cheaper_seating_price ?
                            <p className="add-employee-validation">{errors.cheaper_seating_price}</p> : null}
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>POS Id</Form.Label>
                        <Form.Control type="text" placeholder="Enter POS Id"
                                      value={event.pos_id}
                                      onChange={(e) => handleChangeUser("pos_id", e.target.value)}/>
                        {errors.pos_id ? <p className="add-employee-validation">{errors.pos_id}</p> : null}
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Cheaper Seating POS Id</Form.Label>
                        <Form.Control type="text" placeholder="Enter Cheaper Seating POS Id"
                                      value={event.pos_id}
                                      onChange={(e) => handleChangeUser("cheaper_seating_price", e.target.value)}/>
                        {errors.pos_id ? <p className="add-employee-validation">{errors.pos_id}</p> : null}
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" placeholder="Description" className="description"
                                      value={event.description}
                                      onChange={(e) => handleChangeUser("description", e.target.value)}/>
                        {errors.description ? <p className="add-employee-validation">{errors.description}</p> : null}
                    </Form.Group>
                   
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="DateTimePicker"
                            value={event.start_date_time}
                            onChange={(e) => handleChangeUser("start_date_time", new Date(e).toLocaleString())}
                        />
                    </LocalizationProvider>
                    {/* <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Start Date & Time*</Form.Label>
                            <Form.Control type="text" placeholder="2022/03/13 9:30 pm"
                                value={event.start_date_time}
                                onChange={(e) => handleChangeUser("start_date_time", e.target.value)} />
                                {errors.start_date_time ? <p className="add-employee-validation">{errors.start_date_time}</p> : null}
                        </Form.Group> */}
                        
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>End Date & Time*</Form.Label>
                        <Form.Control type="text" placeholder="2022/03/13 9:30 pm"
                                      value={event.end_date_time}
                                      onChange={(e) => handleChangeUser("end_date_time", e.target.value)}/>
                        {errors.end_date_time ?
                            <p className="add-employee-validation">{errors.end_date_time}</p> : null}
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Start Date & Time Display</Form.Label>
                        <Form.Control type="text" placeholder="Start Date & Time Display"
                                      value={event.start_date_time_display}
                                      onChange={(e) => handleChangeUser("start_date_time_display", e.target.value)}/>
                        {errors.start_date_time_display ?
                            <p className="add-employee-validation">{errors.start_date_time_display}</p> : null}
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>End Date & Time Display</Form.Label>
                        <Form.Control type="text" placeholder="End Date & Time Display"
                                      value={event.end_date_time_display}
                                      onChange={(e) => handleChangeUser("end_date_time_display", e.target.value)}/>
                        {errors.end_date_time_display ?
                            <p className="add-employee-validation">{errors.end_date_time_display}</p> : null}
                    </Form.Group>
                    <Form.Label className="events-mt">Event Position</Form.Label>
                    <Form.Select aria-label="Select Event Position" className="select-event employee-role" width="100%"
                                 value={event.event_position}
                                 onChange={(e) => handleChangeUser("event_position", e.target.value)}>
                        <option>Select Event Position</option>
                        <option value="Top Left"><img src={TopLeftIcon} alt='img' />Top Left</option>
                        <option value="Top Middle">Top Middle</option>
                        <option value="Top Right">Top Right</option>
                        <option value="Center Left">Center Left</option>

                    </Form.Select>
                    <Form.Label className="events-mt">Column Span Of Tile</Form.Label>
                    <Form.Control as="select" aria-label="Select Event Position" className="select-event employee-role"
                                  width="100%"
                                  value={event.column_span_of_tile}
                                  onChange={(e) => handleChangeUser("column_span_of_tile", e.target.value)}>
                        <option>Please select Col span of tile</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>

                    </Form.Control>
                    <Form.Label className="events-mt">Row Span Of Tile</Form.Label>
                    <Form.Select aria-label="Select Event Position" className="select-event employee-role" width="100%"
                                 value={event.row_span_of_tile}
                                 onChange={(e) => handleChangeUser("row_span_of_tile", e.target.value)}>
                        <option>Please select Row span of tile</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>

                    </Form.Select>
                    {eventId ?
                        <div>
                            <h5>Image</h5>
                            <ImageUpload>
                                {/* <h6>Upload Kitchen Image <span onClick={() => this.handleFormUpload("image_url")}>Browse</span></h6> */}
                                {event.image_url ?
                                    <div>
                                        <img src={event.image_url} onClick={() => handleFormUpload("image_url")}
                                             alt="Text Here"/>
                                        {/* <a target={"_blank"}
                                                            href={`${this.props.kitchen.images}`}>{'File'}</a> */}
                                    </div>
                                    :
                                    <h6>Upload Event Image <span
                                        onClick={() => handleFormUpload("image_url")}>Browse</span></h6>
                                }
                            </ImageUpload>
                        </div>

                        :
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Image</Form.Label>
                            <ImageUpload>
                                <h6>Upload Event Image <span onClick={() => handleFormUpload("image_url")}>Browse</span>
                                </h6>
                            </ImageUpload>
                        </Form.Group>
                    }

                    <div className="event-buttons">
                        <div className="cancel-btn">
                            <button>Cancel</button>
                        </div>
                        <div className="save-btn">
                            <button onClick={(e) => handleSubmit(e)}>{eventId ? "Update" : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AddEvent;
