import {Button, Modal, ModalBody} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {errorMsg, successMsg} from "../../../util/toast";

import { Link } from "react-router-dom";
import Pencil from "../../../assets/pencil.svg";
import TableFooter from "../../../hoc/TableFooter";
import callApi from "../../../util/apiCaller";

const EventTable = ({
                       pageSize,
                       pageNum,
                       event_count,
                       pages,
                       handlePageSizeChange,
                       handlePageNumChange,
                       eventList
                   }) => {


    //  const [eventList, seteventList] = useState([]);
    
//   console.log();
//   console.log("concert",eventList);
   
    return (
        <>
            <div className="">
            <TableFooter
                   isPageVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={event_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
                <div style={{ marginRight: "14px" }}>
                            <table class="table table-borderless employee-table">
                                <thead>
                                    <tr className="table-header">
                                        <th scope="col">Name</th>
                                        <th scope="col">Artist</th>
                                        <th scope="col">Start time</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Actions</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventList.map((item, index) => (
                                        <tr className="table-border-bottom table-body">
                                            {console.log("id", item._id)}
                                            <th scope="row">{item.name}</th>
                                            <td style={{ width: "26%" }}>{item.artist}</td>
                                            <td>{item.start_date_time}</td>
                                            <td>{item.price}</td>
                                            <td> <Link to={{pathname: `/admin/events/add-events`, search:`id=${item?._id}`} }  ><img src={Pencil} style={{ marginRight: "5px" }} />Edit</Link></td>
                                            <td><label class="switch">
                                                <input type="checkbox" />
                                                <span class="slider round"></span>
                                            </label></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                 <TableFooter
                    isCounterVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={event_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
            </div>
           
        </>
    )
}

export default EventTable;
