import React, {useEffect, useState} from "react";

import EditIcon from "../../../assets/edit-icon.svg";
import TableFooter from "../../../hoc/TableFooter";
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {setSelectedRestaurant} from "../../../redux/actions/AppActions";
import callApi from "../../../util/apiCaller";
import { errorMsg, successMsg } from "../../../util/toast";
import callApiV2 from "../../../util/apiV2Caller";


const RestaurantTable = ({
                             pageSize,
                             pageNum,
                             restaurant_count,
                             pages,
                             handlePageSizeChange,
                             handlePageNumChange,
                             restaurantList,
                             setRestaurantList
                         }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleView = (item) => {
        dispatch(setSelectedRestaurant(item));
        navigate("/admin/restaurant")
    }

    const handleToggle = (id, value) => {
        

        callApiV2(`restaurant/${id}/update`,"post", {
            restaurant : {
                is_order_flow_active : value
            }
        })
        .then(res => {
            let newList = restaurantList.map(rest => {
                if(rest._id === id){
                    return res?.data?.restaurant
                }
                return rest
            })
            setRestaurantList(newList)
            successMsg("Successfully Updated!")

        }) 
        .catch(err => {
            errorMsg("Something went wrong!")
            console.log(err)
        })
    }

    return (
        <>
            <div className="">
                <TableFooter
                    isPageVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={restaurant_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
                <div className="dish-body list-card-bg-color p-0"
                     style={{marginRight: '14px', background: 'rgb(255, 255, 255'}}>
                    <table class="table table-borderless restaurent-table">
                        <thead class="thead-light sticky-top">

                        <th scope="col">Restaurant Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Actions</th>
                        <th scope="col" style={{textAlign:"center"}}>Order Flow</th>
                        </thead>
                        <tbody>
                        {restaurantList.map((item, index) => (
                            <tr className="table-border-bottom table-body">
                                <td scope="row">{item.name}</td>
                                <td>{item.address}</td>
                                <td>{item.currency}</td>
                                <td>
                                    <a style={{cursor: 'pointer'}} onClick={() => handleView(item)}>
                                        <img src={EditIcon} style={{marginRight: "7px"}}/>View
                                    </a>
                                </td>
                                <td style={{textAlign:"center"}}  data-tip="Inactive" data-class="upload-data-tip">
                                        <label class="switch">
                                            <input type="checkbox" checked={item.is_order_flow_active} onClick={(e) => handleToggle(item._id, !item.is_order_flow_active)} />
                                            <span class="slider round"></span>
                                        </label>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <TableFooter
                    isCounterVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={restaurant_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>


            </div>

        </>
    )
}

export default RestaurantTable;
