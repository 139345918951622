import React, {useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import {useSelector, useDispatch} from "react-redux";
import {getBusiness} from "../../../redux/reducers/AppReducer";
//import callApi, {callUploadApi} from "../../../util/apiCaller";
import FormData from 'form-data';

import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import TopLeftIcon from "../../../assets/top-left.svg";
import TopRightIcon from "../../../assets/top-right.svg";
import TopMiddleIcon from "../../../assets/top-middle.svg";
import CenterLeftIcon from "../../../assets/center-left.svg";
import {toast} from 'react-toastify';
import _ from "lodash";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {createOTP} from "../../../util/Utility";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import {Link} from "react-router-dom";
import callApiV2, {callUploadApi} from "../../../util/apiV2Caller";


const yes = "success";
const no = "error";
const update = "update";
const noUpdate = "noUpdate";

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ImageUpload = styled.div`
  border: 1px solid #dbd9d9;
  width: auto;
  height: 168px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  display: block;

  h6 {
    padding: 60px;

  }

  h5 {
    margin-top: 50px;

  }

  span {
    color: #606734;
  }

  img {
    border: 1px solid #dbd9d9;
    width: auto;
    height: 168px;
    border-radius: 8px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

`

const AddRestaurant = (props) => {
    const [event, setEvent] = useState({})
    const [errors, setErrors] = useState({});
    const query = useQuery();
    const eventId = query.get('id');
    const business = useSelector(state => getBusiness(state));
    
    const notify = (data) => {
        if (data === "success") {
            toast("Event added successfully..!")
        } else if (data === "error") {
            toast("Something went wrong..!")
        } else if (data === "update") {
            toast("Event Updated")
        } else if (data === "noUpdate") {
            toast("Something went wrong..!")
        }

    }
    const deleteFileUploadFileInput = () => {
        // native JS
        let fileInput = document.getElementById("PageUpload");
        document.getElementById('root').removeChild(fileInput)
    };
    const onFileUpload = (file) => {
        
        const formdata = new FormData();
        formdata.append("file", file);
        callUploadApi(`media-upload`, "post", formdata)
            .then(res => {
               
                let updateEvent = {};
                let keyName = document.getElementById("PageUpload").dataset.keyName;
                updateEvent[keyName] = res.data.link;
                
                //updateEvent(eventId, updateEvent);
                handleChangeUser("images", [res.data.link])
                deleteFileUploadFileInput();
            });
    };
    const onFileChange = event => {
        // Update the state
        
        onFileUpload(event.target.files[0]);
    };
    const handleFormUpload = (val) => {
        let input = document.createElement('input');
        input.type = "file";
        input.id = "PageUpload";
        input.style.display = 'none';
        input.dataset.keyName = val;
        input.onchange = onFileChange;
        document.getElementById('root').appendChild(input);
        setTimeout(function () {
            document.getElementById("PageUpload").click();
        }, 200);
    };

    const handleAddEvent = (Newevent, next) => {
       
        callApiV2(`event/new`, 'post',
            {event: Newevent}
        ).then(res => {
            return next(res);
        })
    }

    const handleUpdateEvent = (newEvent, next) => {
     
        callApiV2(`restaurant/new`, 'post',
            newEvent
        ).then(res => {
            return next(res);
        })
    };

   
    const handleValidation = (event) => {
        let isValid = true;
        let newErrors = {};
        if (!event.name) {
            isValid = false;
            newErrors['name'] = 'Please enter name';
        }
        if (!event.address) {
            isValid = false;
            newErrors['address'] = 'Please enter address';
        }
        if (!event.zip_code) {
            isValid = false;
            newErrors['zip_code'] = 'Please enter Zip Code';
        }
        if (!event.country) {
            isValid = false;
            newErrors['country'] = 'Please select country.';
        }
        if (!event.description) {
            isValid = false;
            newErrors['country'] = 'Please select country.';
        }
          
        return {
            isValid,
            errors: newErrors
        }
    };

    const handleChangeUser = (keyName, keyValue) => {
        let update = {...event}
        update[keyName] = keyValue;
        console.log("update", update);
        setEvent(update);

    }

    const removeImage = (imageUploaded) => {
        let newArr = [...event.images];
        newArr.splice(newArr.indexOf(imageUploaded), 1);
        
        let update = {...event, images:newArr}
        console.log(update)
        setEvent(update)
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        let newEvent = {...event}
        newEvent.business_id = business._id
        const check = handleValidation(newEvent);
        if (check.isValid) {
            let UpdateEvent = {
                restaurant : newEvent
            }


            handleUpdateEvent(UpdateEvent, (result) => {
                if (result.status === "Success") {
                    notify(update);
                } else {
                    notify(noUpdate);
                }
            });
        } else {
            setErrors(check.errors)
        }
    }

    return (
        <>

            <div className="employee-part add-events-section">
                <p className="events-breadcrum">Restautant <span>/ Add Restaurant</span></p>
                <div className="add-event-form">
                    <p>Name and Address</p>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Restaurant Name*</Form.Label>
                        <Form.Control type="text" placeholder="Enter Restaurant Name" className=""
                                      value={event.name}
                                      onChange={(e) => handleChangeUser("name", e.target.value)}

                        />

                        {errors.name ? <p className="add-employee-validation">{errors.name}</p> : null}
                    </Form.Group>
                    
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="Enter Address"
                                      value={event.address}
                                      onChange={(e) => handleChangeUser("address", e.target.value)}/>
                        {errors.address ? <p className="add-employee-validation">{errors.address}</p> : null}
                    </Form.Group>

                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Country</Form.Label>
                        <br></br>
                        <CountryDropdown
                            value={event.country}
                            onChange={(val) => handleChangeUser("country", val)} />
                        {errors.country ?
                            <p className="add-employee-validation">{errors.country}</p> : null}
                    </Form.Group>

                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" placeholder="Description" className="description"
                                      value={event.description}
                                      onChange={(e) => handleChangeUser("description", e.target.value)}/>
                        {errors.description ? <p className="add-employee-validation">{errors.description}</p> : null}
                    </Form.Group>

                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" placeholder="Enter Zip Code"
                                      value={event.zip_code}
                                      onChange={(e) => handleChangeUser("zip_code", e.target.value)}/>
                        {errors.zip_code ? <p className="add-employee-validation">{errors.zip_code}</p> : null}
                    </Form.Group>
                    
                    {/* <Form.Group className="input_bottom position-relative" controlId="formBasicEmail"> */}
                        {/* <Form.Label>Dish Availability*</Form.Label> */}
                        {/* <div className="dish_switch">
                            <label class="switch">
                                <input type="checkbox" checked={true}
                                       value={true}
                                       onChange={(e) => props.handleChangedish("is_available", true)}
                                /> */}

                                {/* <input type="checkbox" value={toggle} onChange = {switch_checkbox} /> */}
                                {/* <span class="slider round"></span>
                            </label>
                        </div>
                        <Form.Control
                            type="text"
                            placeholder="Hide Unavailable Items"
                            value="Hide Unavailable Items"
                            onChange={(e) => props.handleChangedish("availability", e.target.value)}
                        />
                    </Form.Group> */}
                   
                    <Form.Label className="events-mt">Upload Image</Form.Label>
                   <div className="image_upload_box">
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ padding: "20px" }}
                            >
                                 {event?.images?.length > 0 ? (
                                    <div className="up_box">
                                        <img src={event.images[0]} id="output" />
                                        <div onClick={() => removeImage(event.images[0])}>
                                            <img
                                                src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                                                className="img_position"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                {event?.images?.length  ? (
                                        <h6 onClick={() => handleFormUpload("image_url")}>
                                            change image
                                        </h6>
                                    ) : (
                                        <h6 style={{ color: "#d7d7d7" }}>
                                        Upload Group Menu Image{" "}
                                        <span
                                            onClick={() => handleFormUpload("image_url")}
                                            style={{ color: "#202738", cursor: "pointer" }}
                                        >
                                            Browse
                                        </span>
                                </h6>
                                    )}      
                                
                            </div>
                    </div>


                    <div className="event-buttons">
                        <div className="cancel-btn">
                            <Link to="/admin/restaurant"><button >Cancel</button></Link>
                        </div>
                        <div className="save-btn">
                            <button onClick={(e) => handleSubmit(e)}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AddRestaurant;
