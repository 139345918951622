import fetch from "isomorphic-fetch";

// export const API_URL = `https://windmill-api.antcreatives.com`;
// export const API_URL = `http://localhost:8050`;
export const API_URL = process.env.REACT_APP_API_URL || `https://wm-api-dev2.antcreatives.com`;

export default function callApi(endpoint, method = "get", body) {
  let token =
    typeof window === "undefined" ? "" : window.localStorage.getItem("token");
  let headers = {};
  headers["content-type"] = "application/json";
  if (token && token !== "") {
    headers.Authorization = `Bearer ${token}`;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: JSON.stringify(body),
  })
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
    .then(
      (response) => response,
      (error) => error
    );
}

export function callUploadApi(endpoint, method = "get", body) {
  const token = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : "";
  const headers = {};
  if (token && token !== "") {
    headers.Authorization = `Bearer ${token}`;
  }
  return fetch(`${API_URL}/${endpoint}`, {
    headers,
    method,
    body,
  })
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
    .then(
      (response) => response,
      (error) => error
    );
}
