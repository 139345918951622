import React from "react";
import {StartersInformation} from "./StartersInformation";
import StartersOperationTime from "./StartersOperationTime";
import StartersScreenDetails from "./StartersScreenDetails";
import callApiV2 from "../../../util/apiV2Caller";

export default class AddStarters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSegment: "Information",
            menuCategoryInfo: {},
            menuTagText: "",
        };
    }

    setSegment = (selectedSegment = "") => {
        this.setState({
            selectedSegment: selectedSegment
        })
    };


    componentDidMount() {
        if (this.props.categoryId) {
            callApiV2(`menu_category/${this.props.categoryId}`, 'get').then(res => {
                if (res.status === "Success") {
                    this.setState({menuCategoryInfo: res.data.menu_category}, () => {
                        this.setState({
                            menuTagText: this.state.menuCategoryInfo?.tags?.join(",")
                        })
                    });
                }
            });
        }
    }

    handleChangeCategoryMenu = (keyName, keyValue) => {
        if (keyName === "menuTagText") {
            this.setState({
                menuTagText: keyValue
            });
        } else {
            let update = {...this.state.menuCategoryInfo}
            update[keyName] = keyValue;
            this.setState({menuCategoryInfo: update});
        }
    }

    render() {
        return (
            <>
                <div style={{padding: "10px 60px 10px 60px"}}>
                    <ul className="d-flex information-modal">
                        <li className="information col-4"
                            style={{fontWeight: this.state.selectedSegment === "Information" ? '600' : '400'}}
                            onClick={() => this.setSegment("Information")}>Information
                        </li>
                        <li className="screen-details col-4"
                            style={{fontWeight: this.state.selectedSegment === "Screen details" ? '600' : '400'}}
                            onClick={() => this.setSegment("Screen details")}>Screen details
                        </li>
                        <li className="operation-time col-4"
                            style={{fontWeight: this.state.selectedSegment === "Operation Time" ? '600' : '400'}}
                            onClick={() => this.setSegment("Operation Time")}>Operation Time
                        </li>
                    </ul>
                </div>
                <div className="row">
                    {this.state.selectedSegment === "Information" ?
                        <StartersInformation
                            setSegment={this.setSegment}
                            closeMenuInformationModal={this.props.closeMenuInformationModal}
                            menuId={this.props.menuId}
                            menuInfo={this.state.menuCategoryInfo}
                            handleChangeCategoryMenu={this.handleChangeCategoryMenu}
                            listPosition={this.props.listPosition}
                        />
                        :
                        null
                    }
                    {this.state.selectedSegment === "Screen details" ?
                        <StartersScreenDetails
                            setSegment={this.setSegment}
                            closeMenuInformationModal={this.props.closeMenuInformationModal}
                            menuId={this.props.menuId}
                            menuInfo={this.state.menuCategoryInfo}
                            menuTagText={this.state.menuTagText}
                            handleChangeCategoryMenu={this.handleChangeCategoryMenu}
                        />
                        :
                        null
                    }
                    {this.state.selectedSegment === "Operation Time" ?
                        <StartersOperationTime
                            setSegment={this.setSegment}
                            closeMenuInformationModal={this.props.closeMenuInformationModal}
                            menuId={this.props.menuId}
                            menuInfo={this.state.menuCategoryInfo}
                            availability_rule={this.state.menuCategoryInfo.availability_rule}
                            availability_daily={this.state.menuCategoryInfo.availability_daily}
                            availability_weekly={this.state.menuCategoryInfo.availability_weekly}
                            availability_monthly={this.state.menuCategoryInfo.availability_monthly}
                            handleSaveMenu={this.props.handleSaveMenu}
                            categoryId={this.props.categoryId}
                            menuTagText={this.state.menuTagText}
                            fetchCategoryList={this.props.fetchCategoryList}
                            handleChangeCategoryMenu={this.handleChangeCategoryMenu}
                            setCategoryInfo={(data) => this.setState({menuCategoryInfo: data})}
                        />
                        :
                        null
                    }
                </div>
            </>
        )
    }
}