import "./documents.css"
import {Helmet} from "react-helmet";
import React, {useEffect, useRef, useState} from "react";
import startCase from 'lodash/startCase';
import DocumentTable from "./Components/DocumentTable";
import MenuLogo from "../../assets/menu-logo.png";
import {getBusiness, getSelectedRestaurant} from "../../redux/reducers/AppReducer";
import {useSelector} from 'react-redux';
import {Modal} from "react-bootstrap";
import shortid from 'shortid';
import callApiV2 from "../../util/apiV2Caller";

const MODEL_NAME = 'document';

const Documents = () => {

    const [event_count, setEventCount] = useState(0);
    const [eventList, seteventList] = useState([]);
    const business = useSelector(state => getBusiness(state));
    const [filterData, setFilterData] = useState({});

    const [imageUploaded, setImageUploaded] = useState([]);
    const imageId = useRef(0)
    const [isAddModalVisible, setModalVisiblity] = useState(false);

    const fetchDocuments = (business_id) => {
        
        callApiV2(`${MODEL_NAME}/list`, 'post', {
            filters: {
                business_id: business_id
            },
            pageNum: filterData.pageNum,
            pageSize: filterData.pageSize,
        }).then(res => {
            if (res.status === "Success") {
                seteventList(res?.data?.list)
                setEventCount(res?.data?.count)
            }
        })
    }

    const handlePageSize = (pageSize) => {
        let searchFilters = {...filterData};
        searchFilters.pageSize = parseInt(pageSize);
        searchFilters.pageNum = 1;
        setFilterData(searchFilters);
    };

    const handlePageNum = (pageNum) => {
        let searchFilters = {...filterData};
        searchFilters.pageNum = parseInt(pageNum);
        setFilterData(searchFilters);
    };
    useEffect(() => {
        setFilterData({
            qtext: '',
            pageNum: 1,
            pageSize: 50,
        })
        fetchDocuments(business?._id);
    }, [business]);

    // useEffect(() => {
    //     fetchDocuments(business?._id);
    // }, [filterData])

    const closeModal = () => {
        setModalVisiblity(false)
    }

    const onFileUpload = (file,businessId) => {
        console.log("file", file);
        let filename = file.name.split(".");
        let ext = filename.pop();
        if (imageId.current == 1) {
            setImageUploaded([URL.createObjectURL(file)]);
        }
        let shortId = shortid.generate();
        callApiV2(`api/s3/sign`, "post", {
            filename: `doc/${shortId}.${ext}`,
            filetype: file.type,
        })
            .then((res) => {
                console.log("signed_request", res);
                const xhr = new XMLHttpRequest();
                console.log("xhr", xhr);
                xhr.upload.addEventListener(
                    "progress",
                    (e) => {
                        console.log(e);
                    },
                    false
                );
                xhr.onreadystatechange = (e) => {
                    if (xhr.readyState === 4) {
                        console.log("xhr1", xhr);
                        if (xhr.status === 200) {
                            let updateEvent = {};
                            updateEvent.name = filename.join(".");
                            updateEvent.short_id = shortId;
                            let keyName = document.getElementById("PageUpload").dataset.keyName;
                            updateEvent[keyName] = res.url;
                            updateEvent.business_id = businessId;
                            handleAddDocument(updateEvent, (result) => {
                                console.log("AddDocument", result);
                                fetchDocuments();
                                setModalVisiblity(false);
                                deleteFileUploadFileInput();
                            })
                        } else {
                            deleteFileUploadFileInput();
                        }
                    }
                };
                xhr.open("PUT", res.signed_request);
                xhr.setRequestHeader("x-amz-acl", "public-read");
                xhr.send(file);
            })
            .catch((err) => console.log(err));
    };

    const deleteFileUploadFileInput = () => {
        // native JS
        let fileInput = document.getElementById("PageUpload");
        document.getElementById("root").removeChild(fileInput);
    };

    const onFileChange = (event) => {
        onFileUpload(event.target.files[0],business._id);
    };

    const handleFormUpload = (val, id) => {
        imageId.current = id
        let input = document.createElement("input");
        input.type = "file";
        input.id = "PageUpload";
        input.style.display = "none";
        input.dataset.keyName = val;
        input.onchange = onFileChange;
        document.getElementById("root").appendChild(input);
        setTimeout(function () {
            document.getElementById("PageUpload").click();
        }, 200);
    };

    const removeImage = (imageUploaded) => {
        console.log("remove", imageUploaded);
        let newArr = [...imageUploaded];
        let ind = newArr.indexOf(imageUploaded);
        newArr.splice(ind, 1);
        setImageUploaded(newArr);
    };


    const handleAddDocument = (document, next) => {
        
        callApiV2(`document/new`, 'post',
            {document: document}
        ).then(res => {
            return next(res);
        })
    }

    const handleUpdateDocument = (documentId, document, next) => {
        console.log("anas", document);
        callApiV2(`document/${documentId}/update`, 'post',
            document
        ).then(res => {
            return next(res);
        })
    };

    return (
        <>
            <div className="employee-header">
                <div><img src={MenuLogo}/></div>
            </div>
            <Helmet>
                <title>Documents | Windmills</title>
            </Helmet>
            <div className="employee-part">
                <div className="employee-section">
                    <p>{`${startCase(MODEL_NAME)}s`}</p>
                    <div className="d-flex">
                        <a onClick={() => setModalVisiblity(true)}>
                            <button className="add-employee-btn">{`Add ${startCase(MODEL_NAME)}`}</button>
                        </a>
                    </div>
                </div>
                <div className="employee-list-bg">
                    <DocumentTable
                        eventList={eventList}
                        event_count={event_count}
                        handlePageSizeChange={handlePageSize}
                        handlePageNumChange={handlePageNum}
                        pageSize={filterData.pageSize}
                        pageNum={filterData.pageNum}
                        pages={[10, 20, 50, 100]}
                        fetchDocuments = {fetchDocuments}
                    />
                </div>
            </div>
            <div className="delete-modal">
                <Modal show={isAddModalVisible} onHide={closeModal}>
                    <Modal.Header closeButton className="border-none">
                        Add Document
                    </Modal.Header>
                    <Modal.Body>
                        <div className="image_upload_box">
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{padding: "20px"}}
                            >
                                {imageUploaded.length ? (
                                    <div className="up_box">
                                        <img src={imageUploaded[0]} id="output"/>
                                        <div onClick={() => removeImage(imageUploaded)}>
                                            <img
                                                src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                                                className="img_position"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div
                                    style={{
                                        margin: "0 auto",
                                        border: "1px solid rgba(50, 50, 50, 0.2)",
                                        height: "116px",
                                        borderRadius: "15px",
                                        padding: "40px 20px",
                                    }}
                                >
                                    {imageUploaded.length ? (
                                        <h6 onClick={() => handleFormUpload("url", 1)}>
                                            change image
                                        </h6>
                                    ) : (
                                        <h6 style={{color: "#d7d7d7"}}>
                                            Upload Document {" "}
                                            <span
                                                onClick={() => handleFormUpload("url", 1)}
                                                style={{color: "#202738", cursor: "pointer"}}
                                            >
                        Browse
                      </span>
                                        </h6>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )

}

export default Documents;
