import React, {useEffect, useState} from 'react';
import FormData from 'form-data';

import Form from 'react-bootstrap/Form';
import _ from "lodash";
import callApiV2, {callUploadApi} from "../../../util/apiV2Caller";
import styled from "styled-components";
import {convertImageBaseUrl} from "../../../util/imageUpload";

const ImageUpload = styled.div`
  border: 1px solid #dbd9d9;
  width: auto;
  height: 168px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  display: block;

  h6 {
    padding: 60px;
  }

  h5 {
    margin-top: 50px;
  }

  span {
    color: #606734;
  }

  img {
    border: 1px solid #dbd9d9;
    width: auto;
    height: 168px;
    border-radius: 8px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const MenuInformation = (props) => {

    const [errors, setError] = useState({});

    const [imageUploaded, setImageUploaded] = useState([]);

    useEffect(() => {
        if (props.menuInfo?.image_url) {
            setImageUploaded([props.menuInfo?.image_url])
        }
    }, [props.menuInfo]); 
    const deleteFileUploadFileInput = () => {
        // native JS
        let fileInput = document.getElementById("PageUpload");
        document.getElementById('root').removeChild(fileInput)
    };
    const onFileUpload = (file) => {
        console.log("file", file);
        const formdata = new FormData();
        formdata.append("file", file);
        callUploadApi(`media-upload`, "post", formdata)
            .then(res => {
                console.log("resp", res);
                let updateEvent = {};
                let keyName = document.getElementById("PageUpload").dataset.keyName;
                updateEvent[keyName] = res.data.link;
                console.log(updateEvent, "updateKitchen");
                if (props.handleChangeGroupMenu) {
                    props.handleChangeGroupMenu('image_url', res.data.link)
                }
                //updateEvent(eventId, updateEvent);
                deleteFileUploadFileInput();
            });
    };
    const onFileChange = event => {
        // Update the state
        console.log("event", event);
        onFileUpload(event.target.files[0]);
    };
    const handleFormUpload = (val) => {
        let input = document.createElement('input');
        input.type = "file";
        input.id = "PageUpload";
        input.style.display = 'none';
        input.dataset.keyName = val;
        input.onchange = onFileChange;
        document.getElementById('root').appendChild(input);
        setTimeout(function () {
            document.getElementById("PageUpload").click();
        }, 200);
    };
    const removeImage = (imageUploaded) => {
        console.log("remove", imageUploaded);
        let newArr = [...imageUploaded];
        let ind = newArr.indexOf(imageUploaded);
        newArr.splice(ind, 1);
        setImageUploaded(newArr);
    };
    const handleValidation = (groupMenu) => {
        let isValid = true;
        let newErrors = {};
        return {
            isValid,
            errors: newErrors
        }

    }

    const handleNextInfo = () => {
        const check = handleValidation(props.menuInfo);

        if (check.isValid) {

            props.setSegment("Screen details");

        } else {
            setError(check.errors)
        }
    }


    console.log("menuInfo", props.menuInfo);
    return (
        <>
            <div className="col-md-6">
                <Form className="menu-information">
                    <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>Tile Name </Form.Label>
                        <Form.Control
                            value={props.menuInfo?.name}
                            type="text"
                            placeholder="Enter Category Name"
                            onChange={(e) => props.handleChangeGroupMenu("name", e.target.value)}
                        />
                    </Form.Group>
                    {!props.menuInfo.name ? errors.name ?
                        <p className="add-employee-validation">{errors.name}</p> : null : null}
                    <Form.Group className="events-mt" controlId="formBasicEmail">
                        <Form.Label>POS Id </Form.Label>
                        <Form.Control
                            value={props.menuInfo?.pos_id}
                            type="text"
                            placeholder="Enter POS Id"
                            onChange={(e) => props.handleChangeGroupMenu("pos_id", e.target.value)}
                        />
                    </Form.Group>
                    {!props.menuInfo?.pos_id ? errors.pos_id ?
                        <p className="add-employee-validation">{errors.pos_id}</p> : null : null}
                    <Form.Label className="events-mt">Show order delivery option </Form.Label>
                    <Form.Select
                        className="select-event menu-select"
                        width="100%"
                        value={props.menuInfo?.is_order_delivery_shown}
                        onChange={(e) => props.handleChangeGroupMenu("is_order_delivery_shown", e.target.value)}
                    >
                        <option>Select option for delivery show</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </Form.Select>
                    {!props.menuInfo.is_order_delivery_shown ? errors.is_order_delivery_shown ?
                        <p className="add-employee-validation">{errors.is_order_delivery_shown}</p> : null : null}
                    <Form.Label className="events-mt">Station </Form.Label>
                    <Form.Select aria-label="Super Admin"
                                 className="select-event menu-select" width="100%"
                                 value={props.menuInfo?.station_id}
                                 onChange={(e) => props.handleChangeGroupMenu("station_id", e.target.value)}
                    >
                        {props.stations?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                    {!props.menuInfo.station_id ? errors.station_id ?
                        <p className="add-employee-validation">{errors.station_id}</p> : null : null}
                    <Form.Label className="events-mt">Robot Station </Form.Label>
                    <Form.Select aria-label="Super Admin" className="select-event menu-select" width="100%"
                                 value={props.menuInfo?.robot_station_id}
                                 onChange={(e) => props.handleChangeGroupMenu("robot_station_id", e.target.value)}
                    >
                        {props.robotStations?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                    {!props.menuInfo.robot_station_id ? errors.robot_station_id ?
                        <p className="add-employee-validation">{errors.robot_station_id}</p> : null : null}
                    <div>
                       
                    <br></br>
                    <div style={{display:"flex", justifyContent :"space-between", backgroundColor : "white", borderRadius : "8px", border:"1px solid #0001", padding : "10px"}}>
                        <Form.Label style={{margin : 0}}>Default</Form.Label>
                        <label class="switch">
                            <input type="checkbox" checked={props.menuInfo?.is_default}
                                    onClick={() => props.handleChangeGroupMenu("is_default",!props.menuInfo?.is_default)}/>
                            <span class="slider round"></span>
                        </label>
                    </div>
                    </div>
                    
                </Form>
            </div>
            <div className="col-md-6">
                <Form>
                    <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>Header Name </Form.Label>
                        <Form.Control
                            value={props.menuInfo?.label_name}
                            type="text"
                            placeholder="Enter Category Name"
                            onChange={(e) => props.handleChangeGroupMenu("label_name", e.target.value)}
                        />
                    </Form.Group>
                    {!props.menuInfo?.label_name ? errors.label_name ?
                        <p className="add-employee-validation">{errors.label_name}</p> : null : null}
                    <Form.Group className="textarea_input" controlId="formBasicEmail">
                        <Form.Label>Description </Form.Label>
                        <Form.Control
                            type="text"
                            rows="4"
                            cols="50"
                            placeholder="Description"
                            as="textarea" row={3}
                            className='information-description'
                            value={props.menuInfo?.description}
                            onChange={(e) => props.handleChangeGroupMenu("description", e.target.value)}
                        />
                    </Form.Group>
                    {!props.menuInfo?.description ? errors.description ?
                        <p className="add-employee-validation">{errors.description}</p> : null : null}

                    <Form.Label>Static Tile </Form.Label>
                    <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                        <div className="dish_switch">
                            <label className="switch">
                                <input type="checkbox" checked={props.menuInfo?.is_static_tile}
                                       value={props.menuInfo?.is_static_tile}
                                       onChange={(e) => props.handleChangeGroupMenu("is_static_tile", !props.menuInfo.is_static_tile)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <Form.Control
                            disabled={true}
                            type="text"
                            placeholder=""
                            value={""}
                        />
                    </Form.Group>
                    {!errors.is_static_tile ? errors.is_static_tile ?
                        <p className="add-employee-validation">{errors.is_static_tile}</p> : null : null}
                    <div className='information-input-box events-mt'>
                        <h5 className='menu-information-image-head'>{props.menuId ? "Update Image" : "Add Image"} </h5>
                        <div className="image_upload_box">
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{padding: "20px"}}
                            >
                                {imageUploaded.length ? (
                                    <div className="up_box">
                                        <img src={convertImageBaseUrl(imageUploaded[0])} id="output"/>
                                        <div onClick={() => removeImage(imageUploaded)}>
                                            <img
                                                src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                                                className="img_position"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div
                                    style={{
                                        margin: "0 auto",
                                        border: "1px solid rgba(50, 50, 50, 0.2)",
                                        height: "116px",
                                        borderRadius: "15px",
                                        padding: "40px 20px",
                                    }}
                                >
                                    {imageUploaded.length ? (
                                        <h6 onClick={() => handleFormUpload("image_url")}>
                                            change image
                                        </h6>
                                    ) : (
                                        <h6 style={{color: "#d7d7d7"}}>
                                            Upload Group Menu Image{" "}
                                            <span
                                                onClick={() => handleFormUpload("image_url")}
                                                style={{color: "#202738", cursor: "pointer"}}
                                            >
                                                Browse
                                            </span>
                                        </h6>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className='d-flex justify-content-between'>
                <button onClick={() => props.onHide()}
                        className='information-btn-cancel'>Cancel
                </button>
                <button onClick={() => handleNextInfo()} className='information-btn-save'>Next
                </button>
            </div>
        </>
    );


}

export default MenuInformation;
