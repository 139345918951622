import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getSelectedRestaurant} from '../../redux/reducers/AppReducer'
import Form from "react-bootstrap/Form";
import {handleFormUpload} from '../../util/imageUpload';
//import callApi from '../../util/apiCaller';
import {successMsg} from '../../util/toast';
import {Link} from 'react-router-dom';
import {fetchContentByCode} from '../../util/Utility';
import RestaurantHeader from "../RestaurantListPage/Components/RestaurantHeader";
import callApiV2 from '../../util/apiV2Caller';

export default function RestaurantInformation() {

//   const dispatch = useDispatch()
    const selectedRestaurant = useSelector(state => getSelectedRestaurant(state))
    const [restaurantInfo, setRestaurantInfo] = useState({})
    const [currency, setCurrency] = useState(null)

    useEffect(() => {
        if (selectedRestaurant) {
            setRestaurantInfo(selectedRestaurant)
        }
    }, [selectedRestaurant])

    useEffect(() => {
        fetchContentByCode('currency', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setCurrency(content?.params)
            }
        });
    }, [])

    const save = () => {
        callApiV2(`restaurant/${selectedRestaurant?._id}/update`, 'post', {
            restaurant: restaurantInfo
        })
            .then(res => {
                successMsg("Restaurant Information Updated!")
            })
            .catch(err => console.log(err))
    }

    const handleRestaurantInfo = (key, value) => {
        let update = {...restaurantInfo}
        update[key] = value
        setRestaurantInfo(update)
    }
    // console.log("res,",restaurantInfo)
    return (
        <div>
            <div style={{padding: "30px", width: "75%", float: "right"}}>
                <RestaurantHeader
                    links={[{
                        text: 'Information',
                        link: ''
                    }]}
                />
                <h3 style={{marginTop: '20px', marginBottom: "40px"}}>Restaurant Information</h3>

                <div style={{padding: "10px"}}>
                    <Form className="menu-information">
                        <h5>Restaurant Name</h5>
                        <Form.Control type="text"
                                      placeholder="Enter Restaurant Name"
                                      value={restaurantInfo?.name ? restaurantInfo?.name : null}
                                      className="rest_input"
                                      onChange={(e) => handleRestaurantInfo("name", e.target.value)}
                        />

                        <h5>Restaurant Address</h5>
                        <Form.Control type="text"
                                      placeholder="Enter Address"
                                      value={restaurantInfo?.address ? restaurantInfo?.address : null}
                                      className="rest_input"
                                      onChange={(e) => handleRestaurantInfo("address", e.target.value)}

                        />

                        <h5>Restaurant Address</h5>
                        <Form.Control type="text"
                                      placeholder="Enter Description"
                                      value={restaurantInfo?.description ? restaurantInfo?.description : null}
                                      className="rest_input"
                                      onChange={(e) => handleRestaurantInfo("description", e.target.value)}
                        />

                        <h5>Restaurant Currency</h5>
                        <Form.Select
                            aria-label="Currency Select"
                            className="select-event_form menu-select input_bottom"
                            value={restaurantInfo?.currency ? restaurantInfo?.currency : null}
                            width="100%"
                            onChange={(e) => handleRestaurantInfo("currency", e.target.value)}
                        >
                            {currency?.map(item => (
                                <option key={item.value} value={item.value}>{item.label}</option>
                            ))}
                        </Form.Select>

                        <h5 style={{marginTop: "1rem"}}>Payment QR Code</h5>
                        <div className="image_upload_box">
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{padding: "20px"}}
                            >
                                {restaurantInfo?.payment_qr_link ? (
                                    <div className="up_box">
                                        <img src={restaurantInfo?.payment_qr_link} id="output"/>
                                        <div onClick={() => handleRestaurantInfo("payment_qr_link", "")}>
                                            <img
                                                src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                                                className="img_position"
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div
                                    style={{
                                        margin: "0 auto",
                                        border: "1px solid rgba(50, 50, 50, 0.2)",
                                        height: "116px",
                                        borderRadius: "15px",
                                        padding: "40px 20px",
                                    }}
                                >
                                    {restaurantInfo?.payment_qr_link ? (
                                        <h6 onClick={() => handleFormUpload("payment_qr_link", handleRestaurantInfo)}
                                            style={{cursor: "pointer"}}>
                                            Change
                                        </h6>
                                    ) : (
                                        <h6 style={{color: "#d7d7d7"}}>
                                            Upload QR Code{" "}
                                            <span
                                                onClick={() => handleFormUpload("payment_qr_link", handleRestaurantInfo)}
                                                style={{color: "#202738", cursor: "pointer"}}
                                            >
                            Browse
                            </span>
                                        </h6>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            {/* <button onClick = {() => setRestaurantInfo({})} >Cancel</button> */}
                            <Link style={{width: "40%"}} to="/admin/restaurant">
                                <button style={{width: "100%"}} className="cancel_but">Cancel</button>
                            </Link>
                            <button onClick={() => save()} className="save_but" style={{width: "40%"}}>Save</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
