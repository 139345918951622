import {APP_TOKEN} from "../../constants/enums/appEnums";
import callApiV2 from "../../util/apiV2Caller";

export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_AGENT = 'SET_AGENT';
export const SET_RESTAURANT_LIST = 'SET_RESTAURANT_LIST';
export const SET_RESTAURANT = 'SET_RESTAURANT';
export const SET_BUSINESS = 'SET_BUSINESS';

export const setRestaurantList = restaurant_list => ({
    type: SET_RESTAURANT_LIST,
    restaurant_list,
});

export const setSelectedRestaurant = selected_restaurant => ({
    type: SET_RESTAURANT,
    selected_restaurant,
});

export const setBusiness = business => ({
    type: SET_BUSINESS,
    business,
});

export const setAgent = agent => ({
    type: SET_AGENT,
    agent,
});

export function loginUser(creds) {
    return async dispatch => {
        try {
            dispatch(SET_IS_LOADING(true));
            const res = await callApiV2('auth/user/login', 'post', creds);

            dispatch(SET_IS_LOADING(false));
            if (res && res.status === 'Success') {
                const { token } = res.data;
                window.localStorage.setItem(APP_TOKEN, token);
                window.location = "/";
            } else {
                dispatch(SET_IS_LOADING(false));
            }

        } catch (err) {
            dispatch(SET_IS_LOADING(false));
        }
    }
}
