import "./employeePage.css";

import React, {useEffect, useState} from "react";

import EditIcon from "../../assets/edit-icon.svg";
import EmployeeList from '../../content/EmployeeList.json';
import EmployeeTable from "./Components/EmployeeTable";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import MenuLogo from "../../assets/menu-logo.png";
import callApi from "../../util/apiCaller";
import {getBusiness} from "../../redux/reducers/AppReducer";
import {useSelector} from 'react-redux';
import callApiV2 from "../../util/apiV2Caller";

const EmployeePage = () => {
    const [employeeList, setemployeeList] = useState([])
    const [employee_count, setemployeeCount] = useState(0);
    const business = useSelector(state => getBusiness(state));

    const fetchEmployeeList = (business_id, inputFilters) => {
        let filters = {};
        filters.business_id = business_id;
        if (inputFilters && inputFilters.qtext) {
            filters.qtext = inputFilters.qtext;
        }
        callApiV2(`agent/list`, 'post', {
            filters,
            pageNum: inputFilters?.pageNum,
            pageSize: inputFilters?.pageSize,
        }).then(res => {
            if (res?.status === "Success") {
                setemployeeList(res?.data?.list)
                setemployeeCount(res?.data?.count);
            } else {
                console.log('err', res?.data?.message);
            }
        });
    };

    // useEffect(()=>{
    //     callApi(`agent/list`, 'post', {}).then(res => {
    //         if (res.status === "Success") {

    //         }
    //      })
    // },[])

    const handlePageSize = (pageSize) => {
        let searchFilters = {...filterData};
        searchFilters.pageSize = parseInt(pageSize);
        searchFilters.pageNum = 1;
        setFilterData(searchFilters);
        fetchEmployeeList(business._id, searchFilters);
    };
    const handlePageNum = (pageNum) => {
        let searchFilters = {...filterData};
        searchFilters.pageNum = parseInt(pageNum);
        setFilterData(searchFilters);
        fetchEmployeeList(business._id, searchFilters);
    };
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        setFilterData({
            qtext: '',
            pageNum: 1,
            pageSize: 50,
        })
        fetchEmployeeList(business._id, null);
    }, [business]);

    console.log("list", employeeList);
    return (
        <>
            <div className="employee-header">
                <Helmet>
                    <title>Windmills | Employee</title>
                </Helmet>
                <div><img src={MenuLogo}/></div>
            </div>
            <div className="employee-part">

                <div className="employee-section">
                    <p>List Employee</p>
                    <Link to="/admin/employees/new-employee">
                        <button className="add-employee-btn">Add Employee</button>
                    </Link>
                </div>
                <EmployeeTable
                    employeeList={employeeList}
                    employee_count={employee_count}
                    handlePageSizeChange={handlePageSize}
                    handlePageNumChange={handlePageNum}
                    pageSize={filterData.pageSize}
                    pageNum={filterData.pageNum}
                    pages={[10, 20, 50, 100]}
                />
            </div>
        </>
    )
}


export default EmployeePage;
