import React, { useState } from "react";
import styled from "styled-components";

import moment from "moment/moment";
import { Button, Modal } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOrderItemStatusChange,
  markOrderComplete,
} from "../../../redux/actions/OrderActions";
import {
  getKitchenTableMap,
  getKitchenUpdateStatus,
} from "../../../redux/reducers/OrderReducer";
import callApiV2 from "../../../util/apiV2Caller";
import { toast } from "react-toastify";

const KdsDiv = styled.div`
  width: 100%;

  .rowContainer {
    background-color: #f5f1e8;
    border-radius: 0px 0px 10px 10px;
    padding: 20px 0 15px 0;
    height: 110px;
    overflow-y: scroll;
  }

  .rowContainer::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;
const ItemHead = styled.div`
  display: flex;
  justify-content: space-between;
  background: #202738;
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  color: white;
  padding: 16px;
  box-sizing: border-box;
  font-size: 13px;
  letter-spacing: 0.25px;
  position: relative;

  p {
    margin: 0;
  }
`;

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 2fr;
  padding: 0 16px 0 16px;
  font-size: 14px;
  font-weight: 500;

  .status {
    border-radius: 10px;
    color: black;
    outline: none;
    width: 90px;
    font-size: 11px;
    height: 24px;
    border: none;
    font-weight: 500;
    letter-spacing: 0.15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btnWrapper {
    display: flex;
    justify-content: flex-end;
  }
`;

export default function KDSTable({ tableOrder }) {
  const dispatch = useDispatch();

  const is_updating_order = useSelector((state) =>
    getKitchenUpdateStatus(state)
  );
  const tableMap = useSelector((state) => getKitchenTableMap(state));
  const [show, setShow] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedOrderItem, setOrderItem] = useState({});
  const [selectionState, setSelectionState] = useState("");

  let date = moment();

  const getDishOrderStatus = (order) => {
    let status = order?.order_status
      ? "cooking"
      : order?.is_cancelled
      ? "Cancelled"
      : order?.is_delivered
      ? "Delivered"
      : order?.is_processed
      ? "Processed"
      : "Unprocessed";
    return status;
  };

  const closeModal = () => {
    setModalVisibility(false);
    setShow(false);
  };

  const selectItem = (item) => {
    setOrderItem(item);
    setModalVisibility(true);
  };

  const cancelOrderChange = () => {
    setOrderItem({});
    setModalVisibility(false);
    setSelectionState("");
  };

  const saveOrderChange = () => {
    if (selectionState) {
      let orderItem = { ...selectedOrderItem };
      if (selectionState === "processed") {
        orderItem.is_processed = true;
      }
      if (selectionState === "delivered") {
        orderItem.is_delivered = true;
      }
      if (selectionState === "cancelled") {
        orderItem.is_cancelled = true;
      }
      dispatch(
        changeOrderItemStatusChange(
          tableOrder._id,
          selectedOrderItem._id,
          orderItem
        )
      );
      cancelOrderChange();
    }
  };

  const markComplete = () => {
    dispatch(markOrderComplete(tableOrder._id));
    cancelOrderChange();
  };

  const getTableName = (table_id) => {
    return tableMap ? tableMap[table_id]?.table_name : "NA";
  };
  const handleAcceptApi = async (orderId, itemId) => {
    const res = await callApiV2(
      `captain/order/${orderId}/accept-orders`,
      "post",
      {
        orderDetails: [itemId],
      }
    );
    if (res.status === "Success") {
      setShow(true);
      toast("order accepted");
    }
  };
  const handleRejectApi = async (orderId, itemId) => {
    const res = await callApiV2(
      `captain/order/${orderId}/reject-orders`,
      "post",
      {
        orderDetails: [itemId],
      }
    );
    if (res.status === "Success") {
      closeModal();
      toast("order rejected");
    }
  };

  return (
    <KdsDiv>
      <ItemHead>
        <p>Table : {getTableName(tableOrder?.table_id)}</p>
        <p>
          {tableOrder?.created_at
            ? moment(tableOrder?.created_at).format("lll")
            : date.format("lll")}
        </p>
      </ItemHead>
      <div className="rowContainer">
        {tableOrder?.order_details?.map((order) => {
          return (
            <ItemRow key={order._id}>
              <p>{order?.order_qty ? `${order?.order_qty}x` : "NA"}</p>
              <p>{order?.dish_name ? order?.dish_name : "NA"}</p>
              <div className="btnWrapper">
                <div
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      order?.order_status === "Delivered"
                        ? "#202738"
                        : "rgba(50,50,50,0.2)",
                    color:
                      order?.order_status === "Delivered" ? "white" : "black",
                  }}
                  className="status"
                  onClick={() => selectItem(order)}
                >
                  {getDishOrderStatus(order)}
                </div>
              </div>
            </ItemRow>
          );
        })}
      </div>
      <div className="delete-modal">
        <Modal show={isModalVisible} onHide={closeModal}>
          <Modal.Header closeButton className="border-none">
            Change Dish Status
          </Modal.Header>
          <Modal.Body>
            <div>
              <h3>{selectedOrderItem.dish_name}</h3>
            </div>
            {show ? null : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <button
                  style={{
                    padding: "1rem",
                    border: "1px solid #111",
                    background: "#0d6efd",
                  }}
                  onClick={() =>
                    handleAcceptApi(tableOrder?._id, selectedOrderItem?._id)
                  }
                >
                  {"Accept"}
                </button>
                <button
                  style={{
                    padding: "1rem",
                    border: "1px solid #111",
                    background: "#0d6efd",
                  }}
                  onClick={() =>
                    handleRejectApi(tableOrder?._id, selectedOrderItem?._id)
                  }
                >
                  {"Reject"}
                </button>
              </div>
            )}
            {show ? (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ padding: "20px" }}
              >
                <ListGroup style={{ width: "100%" }}>
                  <ListGroup.Item
                    onClick={() => setSelectionState("")}
                    active={selectionState === ""}
                  >
                    Reset Selection
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => setSelectionState("processed")}
                    active={selectionState === "processed"}
                  >
                    Mark Processed
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => setSelectionState("delivered")}
                    active={selectionState === "delivered"}
                  >
                    Mark Delivered
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => setSelectionState("cancelled")}
                    active={selectionState === "cancelled"}
                  >
                    Mark Cancelled
                  </ListGroup.Item>
                </ListGroup>
              </div>
            ) : null}
          </Modal.Body>
          {show ? (
            <Modal.Footer>
              <Button variant="primary" onClick={() => markComplete()}>
                {is_updating_order ? "Completing" : "Proceed To Payment"}
              </Button>
              <Button variant="secondary" onClick={() => cancelOrderChange()}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => saveOrderChange()}>
                {is_updating_order ? "Saving" : "Save"}
              </Button>
            </Modal.Footer>
          ) : null}
        </Modal>
      </div>
    </KdsDiv>
  );
}
