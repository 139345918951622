import React, {useState, useEffect} from 'react'
import Brand from '../../assets/windmills-logo.svg'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {AGENT, APP_TOKEN, USER_ID} from "../../constants/enums/appEnums";
import callApi from "../../util/apiCaller";
import {Helmet} from "react-helmet";
import './login.css'
import {errorMsg, successMsg} from "../../util/toast";
import callApiV2 from '../../util/apiV2Caller';

const Login = ({isAuthenticated}) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [agent, setAgentData] = useState({
        username: '',
        password: ''
    });

    useEffect(() => {
        if (!!isAuthenticated) {
            let ref_url = searchParams.get("ref_url");
            navigate(ref_url ? ref_url : "/admin/menu");
        }
    }, [isAuthenticated])

    const [errors, setErrors] = useState({});
    const [eye, seteye] = useState(true);
    const [password, setpassword] = useState("password");
    const [type, settype] = useState(false);


    const handleChangeUser = (keyName, keyValue) => {
        let update = {...agent};
        update[keyName] = keyValue;
        setAgentData(update);
    }

    const handleValidation = (user) => {
        let isValid = true;
        let newErrors = {};
        if (!user.username) {
            isValid = false;
            newErrors['username'] = 'Email is required';
        }
        if (!user.password) {
            isValid = false;
            newErrors['password'] = 'Password is required';
        }
        return {
            isValid,
            errors: newErrors
        }
    };

    const handleLoginAPI = (creds, next) => {
        callApiV2(`auth/agent/login`, 'post', {
            agent: creds
        }).then(res => {
            return next(res);
        })
    };

    const Eye = () => {
        if (password == "password") {
            setpassword("text");
            seteye(false);
            settype(true);
        } else {
            setpassword("password");
            seteye(true);
            settype(false);
        }
    }

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const check = handleValidation(agent);
        if (check.isValid) {
            handleLoginAPI(agent, (result) => {
                if (result.status === "Success") {
                    console.log("anas", result);
                    successMsg("Login Successfully...!");
                    window.localStorage.setItem(APP_TOKEN, result.data.token);
                    window.localStorage.setItem(USER_ID, result.data.agent._id);
                    window.localStorage.setItem(AGENT, JSON.stringify(result.data.agent));
                    setTimeout(() => {
                        window.location = "/admin/menu";
                    }, 1000)
                } else {
                    errorMsg("Incorrect credential");
                    let errors = {};
                    errors.message = "Enter valid mail id";
                    errors.validPassword = "Enter valid password";
                    setErrors(errors);
                }
            });
        } else {
            setErrors(check.errors);
        }
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Login | WindMills</title>
            </Helmet>
            <section className='login-section'>
                <div className="content-box">
                    <div className='login-card'>
                        <div className="form-box">
                            <div>
                                <div className='logo'>
                                    <img src={Brand} className='brand-img' alt=""/>
                                </div>
                                <form onSubmit={handleSubmit} className="form-pd">
                                    <div className="input-box">
                                        <span>Email*</span>
                                        <input
                                            value={agent.username}
                                            onChange={(e) => handleChangeUser("username", e.target.value)} type="text"
                                            placeholder='Enter Email id'/>
                                        {errors && errors.username ?
                                            <label className='login-errors'>{errors.username}</label> : null}

                                    </div>
                                    {errors && errors.message ?
                                        <label className='login-errors'>{errors.message}</label> : null}
                                    <div className="input-box">
                                        <label>Password*</label>
                                        <div className='position-relative'>
                                            <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                                               aria-hidden="true"></i>
                                            <input
                                                value={agent.password}
                                                onChange={(e) => handleChangeUser("password", e.target.value)}
                                                type={password}
                                                placeholder='Enter Password'/>
                                        </div>
                                        {errors && errors.password ?
                                            <label className='login-errors'>{errors.password}</label> : null}
                                    </div>

                                    {errors && errors.validPassword ?
                                        <label className='login-errors'>{errors.validPassword}</label> : null}
                                    <div className="">
                                        <input type="submit" value='login' className='login-btn'/>
                                    </div>
                                    <hr className='login-bottom-border'/>
                                    <div className="remember">
                                        <div className='contact-us'>
                                            <Link to="/forgot-password"><p>Forgot password?</p></Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login
