import React, {useState, useEffect} from "react"
import Form from 'react-bootstrap/Form';
import shortid from 'shortid';
import callApi from "../../../util/apiCaller";
import { errorMsg, successMsg } from "../../../util/toast";
import callApiV2 from "../../../util/apiV2Caller";

const EditCollection = ({collection, handleEditCollection, setSelectedCollection, screenPosition, closeModal}) => {


    const handleSave = () => {
        callApiV2(`menu_collection/${collection._id}/update`, "post", {
            menu_collection : collection
        })
        .then(res => {
            if(res.status === "Success"){
                successMsg(`Collection ${collection.name} updated!`)
                setSelectedCollection(res?.data?.menu_collection)
            }
            if(res.status === "Error"){
                errorMsg(res?.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    console.log("new collection", collection)
    return <div>
    <div className="d-flex justify-content-around" style={{width : "100%"}}>
        <div className="col-md-5">
        <Form className="menu-information">
            <Form.Label className="events-mt">Name </Form.Label>
            <Form.Control
                value={collection?.name}
                type="text"
                placeholder="Enter Collection Name"
                onChange={(e) => handleEditCollection(collection, "name", e.target.value)}
            />
            <Form.Label className="events-mt">Tile Position </Form.Label>
            <Form.Select className="select-event menu-select" width="100%"
                        value={collection?.tile_position}
                        onChange={(e) => handleEditCollection(collection, "tile_position", e.target.value)}
            >
                <option value="">Not Selected</option>
                {screenPosition?.map(item => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                ))}
            </Form.Select>
            <Form.Label className="events-mt">Code</Form.Label>
            <Form.Control
                value={collection?.code}
                type="text"
                placeholder="Enter Collection Name"
                disabled
            />

            <br></br>
            <br></br>
            <div style={{display:"flex", justifyContent :"space-between", backgroundColor : "white", borderRadius : "8px", border:"1px solid #0001", padding : "10px"}}>
                <Form.Label style={{margin : 0}}>Price Shown</Form.Label>
                <label class="switch">
                    <input type="checkbox" checked={collection?.is_price_shown}
                            onClick={() => handleEditCollection(collection, "is_price_shown",!collection?.is_price_shown)}/>
                    <span class="slider round"></span>
                </label>
            </div>
            </Form>
        </div>
        <div className="col-md-5">
        <Form>
        <Form.Label className="events-mt">Screen Position </Form.Label>
        <Form.Select
            className="select-event menu-select" width="100%"
            value={collection?.screen_position}
            onChange={(e) => handleEditCollection(collection, "screen_position", e.target.value)}
            placeholder="Please Enter Screen Position"
        >
            <option value="">Not Selected</option>
            {screenPosition?.map(item => (
                <option key={item.value} value={item.value}>{item.label}</option>
            ))}
        </Form.Select>
        <Form.Label className="events-mt">Items </Form.Label>
        <Form.Control
                value={collection?.item_num}
                type="number"
                placeholder="Enter Item Number"
                min={0}
                onChange={(e) => handleEditCollection(collection, "item_num", e.target.value)}
            />
        <br></br>
        <br></br>
        <div style={{display:"flex", justifyContent :"space-between", backgroundColor : "white", borderRadius : "8px", border:"1px solid #0001", padding : "10px"}}>
            <Form.Label style={{margin : 0}}>Active</Form.Label>
            <label class="switch">
                <input type="checkbox" checked={collection?.is_active}
                        onClick={() => handleEditCollection(collection, "is_active",!collection.is_active)}/>
                <span class="slider round"></span>
            </label>
        </div>
        
    </Form>

    </div>
    
    </div>
    <div className="event-buttons" style={{padding : "4%"}}>
        <div className="cancel-btn" style={{flex : "0 0 35%"}} onClick={closeModal}>
            <button style={{fontSize : "12px"}}>Cancel</button>
        </div>
        <div className="save-btn" style={{flex : "0 0 35%"}} onClick={handleSave}>
            <button style={{ fontSize : "12px"}}>Save</button>
        </div>
    </div>
    </div>
}

export default EditCollection